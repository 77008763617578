<template>
	<b-overlay :show="loading" variant="light" spinner-type="grow">
		<b-form @submit="save" class="text-left">

			<b-alert :show="!!error" variant="danger" v-html="error.message"></b-alert>

			<b-card no-body>
				<b-tabs card>
					<b-tab title="Profile" active>

						<b-form-group
								label="Email address:"
								label-for="profileEmail"
								description="This is also your account username"
								class="mb-4 p-4 shadow-sm"
								:invalid-feedback="validationErrors.email"
								:state="!validationErrors.email"
						>
							<b-form-input
									id="profileEmail"
									v-model="formData.email"
									type="email"
									required
									placeholder="Your email address"
							></b-form-input>
						</b-form-group>

						<b-form-group
								label="Password:"
								label-for="profilePassword"
								class="mb-4 p-4 shadow-sm"
								:invalid-feedback="validationErrors.password"
								:state="!validationErrors.password"
						>

							<b-link v-if="!showChangePassword" @click="showChangePassword = true" class="d-block mt-4">
								Change
							</b-link>
							<b-link v-if="showChangePassword" @click="showChangePassword = false" class="d-block mt-4">
								Cancel
							</b-link>

							<b-form-input
									v-if="showChangePassword"
									id="profilePassword"
									v-model="password"
									type="password"
									required
									autocomplete="off"
									data-lpignore="true"
									placeholder="Enter your new password"
									class="d-inline-block w-50"
							></b-form-input>
							<b-form-input
									v-if="showChangePassword"
									id="profilePasswordConfirm"
									v-model="passwordConfirm"
									type="password"
									autocomplete="off"
									data-lpignore="true"
									required
									placeholder="Confirm your new password"
									class="d-inline-block w-50"
							></b-form-input>

						</b-form-group>

						<b-form-group
								label="First name:"
								label-for="profileFirstName"
								class="mb-4 p-4 shadow-sm"
								:invalid-feedback="validationErrors.firstName"
								:state="!validationErrors.firstName"
						>
							<b-form-input
									id="profileFirstName"
									v-model="formData.firstName"
									required
									placeholder="Enter your first name"
							></b-form-input>
						</b-form-group>

						<b-form-group
								label="Last name:"
								label-for="profileLastName"
								:invalid-feedback="validationErrors.lastName"
								:state="!validationErrors.lastName"
								class="mb-4 p-4 shadow-sm"
						>
							<b-form-input
									id="profileLastName"
									v-model="formData.lastName"
									required
									placeholder="Enter your last name"
							></b-form-input>
						</b-form-group>

					</b-tab>
					<b-tab title="Settings">
						<b-card-text>No settings defined yet!</b-card-text>
					</b-tab>
				</b-tabs>
			</b-card>

			<b-button v-if="!isModal" type="submit" variant="primary">Save Changes</b-button>

		</b-form>
	</b-overlay>
</template>

<script>

import { cloneDeep } from 'lodash-es';

export default {

    name      : 'UserProfileForm',
    mixins    : [],
    components: {},

    props: {

        isModal: {
            type   : Boolean,
            default: false
        }

    },

    data () {
        return {
            loading           : true,
            error             : false,
            formData          : {
                id       : '',
                email    : '',
                firstName: '',
                lastName : ''
            },
            settingsFormData  : {},
            validationErrors  : {},
            showChangePassword: false,
            password          : null,
            passwordConfirm   : null
        };
    },

    methods: {

        async save () {
            try {

                this.loading = true;
                this.error = false;

                const saveData = cloneDeep ( this.formData );
                let requiresLogin = this.formData.email !== this.user.email;
                saveData.settings = this.getSettings ();

                // Password change requested?
                if ( this.showChangePassword ) {

                    if ( this.password !== this.passwordConfirm ) {
                        const e = new Error ();
                        e.errors = [
                            {
                                message: 'Your new and confirm passwords do not match.',
                                fields : [ 'password' ]
                            }
                        ];
                        throw e;
                    }

                    requiresLogin = true;
                    saveData.password = this.password;

                }

                // Save changes
                await this.$store.dispatch ( 'user/save', saveData );
                this.$utils.logger ( 'info', '[AUTH] Changes saved to user profile.' );

                // Login required?
                if ( requiresLogin ) {
                    await this.$alert ( {
                        title: 'Login credentials changed',
                        html : 'Your login credentials have changed, you will need to login again.'
                    } );
                    await this.$store.dispatch ( 'user/logout' );
                    await this.$router.push ( { name: 'login' } );
                    return false;
                }

                this.loading = false;
                return true;

            } catch ( e ) {
                this.error = {
                    message: 'The form has errors, please review and try again.',
                    errors : e.errors
                };
                this.validationErrors = this.$utils.parseFormValidationErrors ( this.error.errors );
                this.loading = false;
                return false;
            }
        },

        getSettings () {
            return {};
        }

    },

    mounted () {

        // Set form data
        this.formData.id = this.user.id;
        this.formData.email = this.user.email;
        this.formData.firstName = this.user.firstName;
        this.formData.lastName = this.user.lastName;
        this.settingsFormData = this.user.settings;

        // Ready
        this.loading = false;
    }

};

</script>

<style scoped lang="scss">

</style>