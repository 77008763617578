<template>
	<div>

		<b-card
				v-if="!importFile"
				title="Choose import file"
				sub-title="Locate your bank statement text file to import"
				class="my-3"
		>
			<b-button variant="dark" @click="selectFile" class="mt-4">Choose File</b-button>
		</b-card>

		<b-card
				v-if="importFile && !matched"
				title="Importing file"
				:sub-title="processingStatus"
				class="my-3"
		>
			<div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
				<span class="sr-only">Loading...</span>
			</div>
		</b-card>

		<b-card
				v-if="matched"
				title="File imported"
				:sub-title="`File ${importFile.name} imported`"
				class="my-3"
		>
			<b-card-text class="mt-4 text-left">
				Your statement has been processed, and {{ matchedPayments }} out of {{ transactions.length }} payments
				have
				been matched.
			</b-card-text>

			<b-card-text class="text-left">
				Click IMPORT below to review this import. Remember to save once you have reviewed!
			</b-card-text>

		</b-card>

		<input
				v-show="false"
				type="file"
				accept=".txt"
				class="custom-file-input"
				@change="onImportFileLoad"
				ref="import_fileBrowser"
		/>

	</div>
</template>

<script>

import { DateTime } from 'luxon';

export default {

    name      : 'PaymentUploadForm',
    mixins    : [],
    components: {},

    props: {

        students: {
            type   : Array,
            default: () => []
        },

        paymentReferences: {
            type   : Array,
            default: () => []
        }

    },

    computed: {},

    data () {
        return {

            importFile      : false,
            processingStatus: '',
            matched         : false,
            transactions    : [],
            matchedPayments : '-',
            prefixes        : [ 'CEMAA', 'CEMA' ]

        };
    },

    methods: {

        selectFile () {
            this.$refs[ 'import_fileBrowser' ].click ();
        },

        async onImportFileLoad ( e ) {

            if ( e.target.files.length === 1 ) {

                // Read the file
                this.importFile = e.target.files[ 0 ];
                const importData = await this.readTextFileInput ( this.importFile );

                // Parse into structured data and match students
                await this.parseImportFile ( importData );

            } else if ( e.target.files.length > 1 ) {
                await this.$alert (
                    {
                        title: 'Import error',
                        html : 'Please choose a single file for importing.'
                    }
                );
            }

        },

        readTextFileInput ( file ) {

            this.processingStatus = 'Reading file...';

            return new Promise ( ( resolve ) => {

                const fileReader = new FileReader ();
                fileReader.onload = function ( file_e ) {
                    return resolve ( file_e.target.result );
                };
                fileReader.readAsText ( file, 'utf-8' );

            } );
        },

        async parseImportFile ( textData ) {

            this.processingStatus = 'Parsing file...';

            // Parse each line to build up a set of records
            let import_lines = textData.split ( '\n' );
            import_lines.shift ();
            import_lines.shift ();
            import_lines.shift ();
            let data = [];
            let item = {};
            import_lines.forEach ( line => {

                if ( line.includes ( '\t\t\t' ) ) {
                    if ( Object.keys ( item ).length > 0 ) {
                        data.push ( item );
                    }
                    item = {};
                } else {

                    if ( line.startsWith ( 'Date' ) ) {
                        item.date = line.replace ( 'Date:', '' )
                            .substring ( 1 )
                            .trim ();
                        item.date = DateTime.fromFormat ( item.date, 'dd/LL/yyyy' )
                            .toISO ();
                    } else if ( line.startsWith ( 'Description' ) ) {
                        item.description = line.replace ( 'Description:', '' )
                            .substring ( 1 )
                            .trim ();
                    } else if ( line.startsWith ( 'Amount' ) ) {
                        item.amount = parseFloat ( line.replace ( 'Amount:', '' )
                            .replace ( '\t', '' )
                            .substring ( 1 )
                            .trim ()
                            .slice ( 0, -1 ) );
                    }

                }

            } );

            // Detect student identifier
            this.processingStatus = 'Matching students...';
            data = data
                .filter ( line => line.amount >= 0 )
                .map ( line => {

                    // Remove all spaces
                    const description = line.description.replace ( / /g, '' );

                    // Look for a variety of spellings and payment references
                    for ( let prefix of this.prefixes ) {

                        const pos = description.indexOf ( prefix );

                        if ( pos > -1 ) {

                            // Walk through next chars
                            let identifier = '';
                            for ( let i = ( pos + prefix.length ), len = description.length; i < len; i++ ) {
                                if ( !isNaN ( parseInt ( description[ i ], 10 ) ) ) {
                                    identifier += description[ i ];
                                } else {
                                    break;
                                }
                            }

                            if ( identifier.length > 0 ) {

                                identifier = parseInt ( identifier, 10 );
                                const student = this.students.find ( x => x.identifier === identifier );
                                if ( student ) {
                                    line.studentId = student.value;
                                }

                            }

                        }

                    }

                    return line;

                } )
            ;

            this.transactions = data;
            this.processingStatus = '';
            this.matchedPayments = this.transactions.filter ( x => x.studentId ).length;
            this.matched = true;

        }

    },

    mounted () {

        // Append any additional payment references to our prefix list
        this.prefixes = this.prefixes.concat ( this.paymentReferences );

    }

};

</script>

<style scoped lang="scss">

</style>