<template>
	<div id="app">

		<template-top-nav/>

		<main class="container-fluid d-flex py-4 bg-light-app">
			<div class="row flex-fill">
				<div class="col-sm-12 flex-fill">
					<router-view/>
				</div>
			</div>
		</main>

		<template-footer/>

	</div>
</template>

<script>

import TemplateTopNav from '@/components/template/TopNav';
import TemplateFooter from '@/components/template/Footer';

export default {

    name      : 'App',
    mixins    : [],
    components: {
        TemplateTopNav,
        TemplateFooter
    },

    computed: {},

    data () {
        return {};
    },

    methods: {},

    async beforeCreate () {
        await this.$store.dispatch( 'user/checkLoginState' );
    },

    created () {

        // Listen for auth events
        this.$eventBus.$on ( 'auth-logged-out', () => {
            this.$store.dispatch ( 'user/logout' );
            this.$router.replace ( { name: 'login' } );
        } );

    }

};
</script>

<style lang="scss">

// Form tweaks
form {
	.text-muted {
		color: lightgrey !important;
	}

	.form-group {

		label {
			margin-bottom: 0;
		}

	}
}

.bg-light-app {
	background-color: #eeeeee !important;
}

.page-inset {
	height: calc(100vh - 347px);
}

.splitpanes--vertical .splitpanes__pane {
	overflow-y: auto;
}

h1 {
	>.text-muted{
		font-size: 1.4rem;
	}
}

</style>

<style scoped lang="scss">

main {
	min-height: calc(100vh - 117px);
}

</style>
