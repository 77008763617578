<template>
	<div>

		<b-alert variant="info" show class="text-left">
			<b>Payment Description:</b><br/><br/>
			{{ description }}
		</b-alert>

		<div>
			<b-input-group class="">

				<template #prepend>
					<b-button :disabled="!filterText" variant="faded" size="sm"
					          class="shadow-none"
					          @click="filterText = null">
						<b-icon v-if="!filterText" icon="search"
						        aria-hidden="true"></b-icon>
						<b-icon v-if="filterText" icon="x-circle-fill"
						        aria-hidden="true"></b-icon>
					</b-button>
				</template>

				<b-form-input
						v-model="filterText"
						size="md"
						placeholder="Search"
				></b-form-input>

			</b-input-group>
		</div>

		<b-list-group class="mt-4 overflow-auto" style="max-height: 30vh;">
			<b-list-group-item
					button
					:active="student && student.value === s.value"
					v-for="s in filteredStudents"
					:key="s.value"
					v-html="s.text"
					class="text-left"
					@click="selectStudent(s)"
			></b-list-group-item>
		</b-list-group>

	</div>
</template>

<script>

import { orderBy } from 'lodash-es';

export default {

    name      : 'PaymentMatchForm',
    mixins    : [],
    components: {},

    props: {

        students: {
            type   : Array,
            default: () => []
        },

        studentId: {
            type: String
        },

        description: {
            type    : String,
            required: true
        }

    },

    computed: {

        filteredStudents () {

            const filter = ( this.filterText || '' ).toLowerCase ();

            return orderBy (
                this.students.filter ( x => {
                    if ( filter.length === 0 ) {
                        return x;
                    } else if ( x.text.toLowerCase ()
                        .includes ( filter ) ) {
                        return x;
                    }
                } ),
                [ 'text' ],
                [ 'asc' ]
            );
        }

    },

    data () {
        return {

            matched   : false,
            student   : null,
            filterText: null

        };
    },

    methods: {

        selectStudent ( student ) {
            if ( this.student && this.student.value === student.value ) {
                this.student = null;
                this.matched = false;
            } else {
                this.student = student;
                this.matched = true;
            }
        }

    }

};

</script>

<style scoped lang="scss">

</style>