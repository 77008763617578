<template>
	<b-navbar fixed variant="dark" type="dark">

		<b-navbar-brand :to="{name: 'dashboard'}">
			<img src="img/icon.png" width="30px" height="30px" alt="CEMAA Icon"/>
			Kick 2.0
		</b-navbar-brand>

		<b-navbar-nav class="ml-auto" v-if="isLoggedIn">

			<b-nav-item :to="{name: 'students'}">Students</b-nav-item>
			<b-nav-item :to="{name: 'payments'}">Payments</b-nav-item>
			<b-nav-item :to="{name: 'paymentImports'}">Payment Imports</b-nav-item>

			<b-nav-item-dropdown right text="Reports">
				<b-dropdown-item :to="{name: 'studentProfileReport'}">Student Profile</b-dropdown-item>
				<b-dropdown-item :to="{name: 'paymentReconciliationReport'}">Payment Reconciliation</b-dropdown-item>
			</b-nav-item-dropdown>

			<b-nav-item-dropdown right text="Lists">
				<b-dropdown-item :to="{name: 'locations'}">Locations</b-dropdown-item>
				<b-dropdown-item :to="{name: 'classes'}">Classes</b-dropdown-item>
				<b-dropdown-item :to="{name: 'transactionTypes'}">Transaction Types</b-dropdown-item>
				<b-dropdown-item :to="{name: 'paymentMethods'}">Payment Methods</b-dropdown-item>
			</b-nav-item-dropdown>

		</b-navbar-nav>

		<b-dropdown v-if="isLoggedIn" no-caret right variant="secondary" class="ml-4 mr-0">
			<template #button-content>
				<b-icon icon="gear-fill" aria-hidden="true"></b-icon>
			</template>
			<b-dropdown-item v-if="isAdmin" :to="{name: 'users'}">Users</b-dropdown-item>
			<b-dropdown-item @click="showProfileEditForm">Profile</b-dropdown-item>
			<b-dropdown-item @click="logout">Logout</b-dropdown-item>
		</b-dropdown>

	</b-navbar>
</template>

<script>

export default {

    name      : 'TemplateTopNav',
    components: {},

    props: {},

    data () {
        return {};
    },

    methods: {

        async logout () {

            await this.$store.dispatch ( 'user/logout' );
            this.$utils.logger ( 'info', '[AUTH] User logged out.' );
            await this.$router.push ( { name: 'login' } );

        },

        async showProfileEditForm () {

            await this.$alertVue (
                {
                    title            : 'Edit your profile',
                    confirmButtonText: 'Save changes',
                    showCancelButton : true,
                    width            : '40rem'
                },
                'UserProfileForm',
                {
                    isModal: true
                },
                async ( component ) => {
                    return component.save ();
                }
            );

        }

    },

    mounted () {
    }

};

</script>

<style scoped lang="scss">
.navbar-nav {
	> .nav-item {
		margin-left: 14px;
	}

	> .nav-item + .nav-item {

		position: relative;

		&:before {
			content: ' • ';
			padding: 0;
			position: absolute;
			top: 2px;
			left: -13px;
			color: inherit;
			font-size: 2rem;
		}

	}
}
</style>