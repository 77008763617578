import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';

// Global plugins
import './plugins/bootstrap-vue';
import './plugins/utils';
import './plugins/event-bus';
import './plugins/http';
import './plugins/alert';

// Global directives
import './directives/Focus';

// Global mixins
import GlobalMixin from '@/mixins/global.mixin';

// Global components
import UserProfileForm      from '@/components/user/ProfileForm';
import PaymentUploadForm    from '@/components/data-forms/PaymentUploadForm';
import PaymentMatchForm     from '@/components/data-forms/PaymentMatchForm';
import ErrorAlert           from '@/components/ErrorAlert';
import { Splitpanes, Pane } from 'splitpanes';

Vue.component ( 'UserProfileForm', UserProfileForm );
Vue.component ( 'PaymentUploadForm', PaymentUploadForm );
Vue.component ( 'PaymentMatchForm', PaymentMatchForm );
Vue.component ( 'ErrorAlert', ErrorAlert );
Vue.component ( 'Splitpanes', Splitpanes );
Vue.component ( 'Pane', Pane );

// App modules
import App    from './KickApp.vue';
import router from './router';
import store  from './store';

// Styles
import '@/assets/css/materia.bootstrap.min.css';
import 'splitpanes/dist/splitpanes.css';

Vue.config.productionTip = false;

Vue.mixin ( GlobalMixin );

new Vue (
    {
        router,
        store,
        render: h => h ( App )
    }
).$mount ( '#app' );
